import axios from 'axios';
import Cookies from 'js-cookie';
const api = axios.create({
    baseURL: process.env.VUE_APP_API_HOST + '/api',
});
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
api.defaults.xsrfCookieName = 'csrftoken';
// api.defaults.headers.common['X-CSRFTOKEN'] = null
api.interceptors.response.use(function (response) {
    if (response.status === 401) {
        console.warn('api: Got 401 error, should redirect to login page.');
        // router.push('/login/')
        return response;
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});
const updateCsrfToken = function () {
    const cookie = Cookies.get('csrftoken');
    api.defaults.headers.common['X-CSRFTOKEN'] = cookie;
    console.log('updated CSRF_TOKEN to: ', cookie);
};
export { api, updateCsrfToken };
