export var Breakpoint;
(function (Breakpoint) {
    Breakpoint[Breakpoint["MOBILE"] = 1] = "MOBILE";
    Breakpoint[Breakpoint["SM"] = 2] = "SM";
    Breakpoint[Breakpoint["MD"] = 3] = "MD";
    Breakpoint[Breakpoint["LG"] = 4] = "LG";
    Breakpoint[Breakpoint["XL"] = 5] = "XL";
})(Breakpoint || (Breakpoint = {}));
export var TableMode;
(function (TableMode) {
    TableMode[TableMode["LOCAL"] = 0] = "LOCAL";
    TableMode[TableMode["REMOTE"] = 1] = "REMOTE";
})(TableMode || (TableMode = {}));
