import { defineComponent } from 'vue';
import UserResult from '@/components/ui/search/UserResult.vue';
import ArtistResult from '@/components/ui/search/ArtistResult.vue';
export default defineComponent({
    components: {
        UserResult,
        ArtistResult,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
});
