import { defineComponent, ref, toRef, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { globalState } from '@/globalStore';
import router from '@/router';
export default defineComponent({
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();
        const activePath = toRef(globalState, 'activePath');
        const isActive = ref(false);
        const sidebarOpen = toRef(globalState, 'sidebarOpen');
        const navigateTo = function (url) {
            sidebarOpen.value = false;
            router.push(url);
        };
        watchEffect(() => {
            if (props.item.url === activePath.value) {
                isActive.value = true;
            }
            else {
                isActive.value = false;
            }
        });
        return {
            route,
            isActive,
            navigateTo,
        };
    },
});
