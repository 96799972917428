import { reactive } from 'vue';
export const globalState = reactive({
    isAuthenticated: false,
    isInitialized: false,
    sidebarOpen: false,
    searchFlyoutOpen: false,
    activePath: '/',
    users: {
        initialized: false,
        data: [],
    },
    artists: {
        initialized: false,
        data: [],
    },
    events: {
        initialized: false,
        data: [],
    },
    search: {
        initialized: false,
        data: [],
    },
    homepageState: {
        logs: [],
        feedbackCount: 0,
        invitesCount: 0,
        activeSessions: 0,
        subscriptions: {
            renewingSum: 0,
            activeCount: 0,
        },
        artists: {
            activeCount: 0,
            newCount: 0,
        },
        users: {
            activeCount: 0,
            newCount: 0,
        },
    },
    navigationItems: [
        {
            url: '/',
            title: 'Dashboard',
            isActive: true,
            icon: 'dashboard',
        },
        {
            url: '/users/',
            title: 'Users',
            isActive: false,
            icon: 'users',
        },
        {
            url: '/artists/',
            title: 'Artists',
            isActive: false,
            icon: 'dj',
        },
        {
            url: '/feedback/',
            title: 'Artist Feedback',
            isActive: false,
            icon: 'feedback',
            number: 16,
        },
        {
            url: '/subscriptions/',
            title: 'Subscriptions',
            isActive: false,
            icon: 'subscription',
            number: 16,
        },
        {
            url: '/events/',
            title: 'Events',
            isActive: false,
            icon: 'event',
        },
        {
            url: '/logs/',
            title: 'Logs',
            isActive: false,
            icon: 'logs',
        },
        {
            url: '/invites/',
            title: 'Invites',
            isActive: false,
            icon: 'invite',
            number: 16,
        },
        {
            url: '/stats/',
            title: 'Statistics',
            isActive: false,
            icon: 'stats',
        },
        {
            url: '/cache/',
            title: 'Cache Invalidation',
            isActive: false,
            icon: 'cache',
        },
        {
            url: '/backups/',
            title: 'Backups',
            isActive: false,
            icon: 'backups',
        },
    ],
    searchDomains: [
        {
            title: 'Artists',
        },
    ],
});
