<template>
  <div class="flex items-center w-full h-screen bg-black">
    <div class="flex justify-center flex-1 w-full text-center">
      <div class="flex items-center text-2xl font-semibold text-gray-300 md:text-4xl lg:text-5xl">
        <icon
          name="spinner"
          class="inline-block w-6 h-6 mr-2 md:w-8 md:h-8 lg:w-10 lg:h-10 animate-spin"
        />
        Loading...
      </div>
    </div>
  </div>
</template>
