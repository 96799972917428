import { defineComponent, computed } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const joined = computed(() => dayjs(props.item.meta.created).format('MMM D, YYYY h:mm A'));
        const lastSeen = computed(() => {
            if (props.item.meta.lastSeen) {
                return dayjs(props.item.meta.lastSeen).format('MMM D, YYYY h:mm A');
            }
            else {
                return dayjs(props.item.meta.created).format('MMM D, YYYY h:mm A');
            }
        });
        return {
            name: props.item.userName,
            photoUrl: props.item.photoUrl,
            email: props.item.email,
            karma: props.item.meta.karma,
            joined,
            lastSeen,
        };
    },
});
