import { isRef, unref, isReactive, toRaw } from 'vue';
import { BetterLogger } from './Logger';
const vueLogger = function (message, obj) {
    if (obj) {
        if (isRef(obj)) {
            message += ' (ref): ';
            obj = unref(obj);
        }
        else if (isReactive(obj)) {
            message += ' (reactive): ';
            obj = toRaw(obj);
        }
        console.log(message, obj);
    }
    else {
        console.log(message);
    }
};
export const useLogger = (options) => {
    window.logger = new BetterLogger({ ...options, logFn: vueLogger });
};
