import { defineComponent, computed } from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
export default defineComponent({
    props: {
        item: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: true,
            default: 'tr',
        },
    },
    setup(props) {
        const timeAgo = computed(() => dayjs().from(dayjs(props.item.timestamp), true));
        const timestamp = computed(() => dayjs(props.item.timestamp).format('DD.MM. HH:mm'));
        return {
            message: props.item.message,
            logType: props.item.logType,
            artist: props.item.dj,
            user: props.item.user,
            timestamp,
            timeAgo,
        };
    },
});
