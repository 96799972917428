<script>
import { defineComponent, markRaw } from 'vue'

import CompactDisc from './icons/CompactDisc.vue'
import ExclamationTriangle from './icons/ExclamationTriangle.vue'
import SignOut from './icons/SignOut.vue'
import Home from './icons/Home.vue'
import List from './icons/List.vue'
import AddressCard from './icons/AddressCard.vue'
import Headphones from './icons/Headphones.vue'
import Database from './icons/Database.vue'
import Hdd from './icons/Hdd.vue'
import TachometerAltFast from './icons/TachometerAltFast.vue'
import FileInvoiceDollar from './icons/FileInvoiceDollar.vue'
import CalendarStar from './icons/CalendarStar.vue'
import Mailbox from './icons/Mailbox.vue'
import StarShooting from './icons/StarShooting.vue'
import ChartBar from './icons/ChartBar.vue'
import CalendarAlt from './icons/CalendarAlt.vue'
import User from './icons/User.vue'
import Heart from './icons/Heart.vue'

export default defineComponent({
  name: 'icon',
  props: ['name'],

  data() {
    return {
      icons: {
        backups: markRaw(Database),
        // bulletin: faBullhorn,
        cache: markRaw(Hdd),
        // chat: faCommentsAlt,
        dashboard: markRaw(TachometerAltFast),
        // desktop: faDesktopAlt,
        dj: markRaw(Headphones),
        event: markRaw(CalendarStar),
        // externalLink: faExternalLink,
        feed: markRaw(CalendarAlt),
        feedback: markRaw(Mailbox),
        heart: markRaw(Heart),
        home: markRaw(Home),
        invite: markRaw(StarShooting),
        logs: markRaw(List),
        // love: faGrinStars,
        // news: faBell,
        // requests: faMoneyCheckEdit,
        // share: faShareSquare,
        stats: markRaw(ChartBar),
        exclamationTriangle: markRaw(ExclamationTriangle),
        logOut: markRaw(SignOut),
        spinner: markRaw(CompactDisc),
        subscription: markRaw(FileInvoiceDollar),
        // tips: faMoneyBillWave,
        // tv: faTvRetro,
        user: markRaw(User),
        users: markRaw(AddressCard),
      },
    }
  },

  computed: {
    icon() {
      if (this.icons[this.name]) {
        return this.icons[this.name]
      } else {
        return this.icons.exclamationTriangle
      }
    },

    css() {
      if (this.icons[this.name]) {
        return null
      } else {
        return { color: '#f00!important' }
      }
    },

    cssClass() {
      if (this.name === 'spinner') {
        return 'fa-spin'
      } else {
        return null
      }
    },
  },
})
</script>

<template>
  <component :is="icon" :style="css" :class="cssClass"></component>
</template>
