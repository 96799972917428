import { defineComponent, inject } from 'vue';
import TIcon from './TIcon.vue';
export default defineComponent({
    components: {
        TIcon,
    },
    setup() {
        const state = inject('state');
        const iconName = inject('iconName');
        return {
            iconName,
            currentOrdering: state.ordering.current,
        };
    },
});
