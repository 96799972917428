<template>
  <svg
    v-once
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="mailbox"
    class="svg-inline--fa fa-mailbox fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M144 64A144 144 0 0 0 0 208v208a32 32 0 0 0 32 32h256V208A144 144 0 0 0 144 64zm80 176a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h128a16 16 0 0 1 16 16zM432 64H244.87C290.23 95.87 320 148.48 320 208v240h224a32 32 0 0 0 32-32V208A144 144 0 0 0 432 64zm80 208a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-48h-56a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h104a16 16 0 0 1 16 16z"
    ></path>
  </svg>
</template>
