import { defineComponent, ref } from 'vue';
import { api, updateCsrfToken } from '@/composition/api';
import router from '@/router';
import { globalState } from '@/globalStore';
export default defineComponent({
    setup() {
        const checkingAuth = ref(true);
        const isSubmitting = ref(false);
        const validationError = ref(false);
        const email = ref('');
        const password = ref('');
        api
            .get('/session-auth/me/')
            .then(function () {
            console.log('Checking auth: we ARE logged in. Redirecting to index.');
            globalState.isAuthenticated = true;
            router.push('/');
        })
            .catch(function () {
            checkingAuth.value = false;
            updateCsrfToken();
        });
        function doLogin() {
            isSubmitting.value = true;
            api
                .post('/session-auth/login/', {
                username: email.value,
                password: password.value,
            })
                .then(function () {
                isSubmitting.value = false;
                globalState.isAuthenticated = true;
                validationError.value = false;
                router.push('/');
            })
                .catch(function (error) {
                console.warn('Login failed: ', error);
                isSubmitting.value = false;
                validationError.value = true;
                password.value = '';
            });
        }
        return {
            email,
            password,
            doLogin,
            isSubmitting,
            validationError,
            checkingAuth,
        };
    },
});
