import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import Icon from '@/components/global/Icon.vue';
import LoadingScreen from '@/components/global/LoadingScreen.vue';
import { useLogger } from './betterLogging/vue';
// @ts-expect-error
import { makeTestServer } from './composition/miragejs/testserver.js';
useLogger({
    level: 'DEBUG',
    packageName: 'Panopticon',
});
logger.log('Logger installed in main');
logger.run();
if (process.env.NODE_ENV === 'test') {
    // @ts-expect-error
    if (window.Cypress) {
        makeTestServer();
    }
}
// if (process.env.NODE_ENV === 'development') {
//   makeDevServer()
// }
createApp(App)
    .component('icon', Icon)
    .component('loading-screen', LoadingScreen)
    .use(store)
    .use(router)
    .mount('#app');
