import { toRef } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { globalState } from '@/globalStore';
import { api, updateCsrfToken } from '@/composition/api';
import Index from '@/views/Index.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Artists from '@/views/Artists.vue';
import Dashboard from '@/views/Dashboard.vue';
const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: Dashboard,
            },
            {
                path: '/artists/',
                name: 'Artists',
                component: Artists,
            },
            {
                path: '/backups/',
                name: 'Backups',
                component: () => import(/* webpackChunkName: "backups" */ './views/Backups.vue'),
            },
            {
                path: '/cache/',
                name: 'Cache',
                component: () => import(/* webpackChunkName: "cache" */ './views/Cache.vue'),
            },
            {
                path: '/events/',
                name: 'Events',
                component: () => import(/* webpackChunkName: "events" */ './views/Events.vue'),
            },
            {
                path: '/feedback/',
                name: 'Feedback',
                component: () => import(/* webpackChunkName: "feedback" */ './views/Feedback.vue'),
            },
            {
                path: '/invites/',
                name: 'Invites',
                component: () => import(/* webpackChunkName: "invites" */ './views/Invites.vue'),
            },
            {
                path: '/logs/',
                name: 'Logs',
                component: () => import(/* webpackChunkName: "logs" */ './views/Logs.vue'),
            },
            {
                path: '/stats/',
                name: 'Stats',
                component: () => import(/* webpackChunkName: "stats" */ './views/Stats.vue'),
            },
            {
                path: '/subscriptions/',
                name: 'Subscriptions',
                component: () => import(
                /* webpackChunkName: "subscriptions" */ './views/Subscriptions.vue'),
            },
            {
                path: '/users/',
                name: 'Users',
                component: () => import(/* webpackChunkName: "users" */ './views/Users.vue'),
            },
        ],
    },
    {
        path: '/login/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/logout/',
        name: 'Logout',
        component: Logout,
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    strict: true,
    routes,
});
router.beforeEach((to, from, next) => {
    if (process.env.NODE_ENV === 'test' && to.path !== '/login/') {
        console.warn('TEST MODE: SETTING AUTH!');
        globalState.isAuthenticated = true;
    }
    if (process.env.NODE_ENV !== 'development' &&
        to.path !== '/login/' &&
        !globalState.isAuthenticated) {
        console.log('router: not authenticated, lets test login.');
        api
            .get('/session-auth/me/')
            .then(function () {
            console.log('Checking auth: we ARE logged in. Redirecting to index.');
            globalState.isAuthenticated = true;
            next();
        })
            .catch(function () {
            console.log('Checking auth: we are NOT logged in. Redirecting to login.');
            updateCsrfToken();
            next({ path: '/login/' });
        });
    }
    else {
        next();
    }
});
router.afterEach(to => {
    const activePath = toRef(globalState, 'activePath');
    activePath.value = to.path;
    console.log('changed activePath to ', activePath.value);
});
export default router;
