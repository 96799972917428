<template>
  <svg
    v-once
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="star-shooting"
    class="svg-inline--fa fa-star-shooting fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M308.68131,191.99637l11.31076,11.30812a15.9975,15.9975,0,0,0,22.62737,0L507.31388,38.62192a15.99872,15.99872,0,0,0,0-22.626L496.00312,4.68584a16.001,16.001,0,0,0-22.62737,0L308.68131,169.36841A16.00107,16.00107,0,0,0,308.68131,191.99637ZM239.99059,91.30917a15.9975,15.9975,0,0,0,22.62737,0l52.69238-52.68725a15.99872,15.99872,0,0,0,0-22.626L303.99958,4.68584a16.001,16.001,0,0,0-22.62737,0L228.67984,57.37309a16.00105,16.00105,0,0,0,0,22.628ZM496.00312,196.67782a16.001,16.001,0,0,0-22.62737,0l-52.69237,52.68725a16.00105,16.00105,0,0,0,0,22.628l11.31075,11.30812a15.9975,15.9975,0,0,0,22.62737,0l52.69238-52.68725a15.99872,15.99872,0,0,0,0-22.626ZM364.29366,267.28619,259.49486,251.99l-46.90712-95.19915c-8.39078-16.99929-32.6881-17.20241-41.18826,0L124.49237,251.99,19.69356,267.28619C.89634,269.98921-6.71318,293.19136,6.9902,306.48768l75.90765,73.99691L64.89752,485.074c-3.20319,18.90546,16.68781,33.108,33.29749,24.20211l93.7986-49.38856,93.79861,49.38856A22.95373,22.95373,0,0,0,319.08971,485.074l-17.891-104.58938,75.892-73.99691C390.7004,293.19136,383.09089,269.98921,364.29366,267.28619Z"
    ></path>
  </svg>
</template>
