import { defineComponent, toRef } from 'vue';
import { globalState } from '@/globalStore';
import { api } from '@/composition/api';
import router from '@/router';
import Sidebar from '@/components/ui/Sidebar.vue';
export default defineComponent({
    components: {
        Sidebar,
    },
    setup() {
        console.log('Index loaded.');
        const isInitialized = toRef(globalState, 'isInitialized');
        if (!isInitialized.value) {
            console.log('not initialized!');
            // const resp = await api.get('/api/panopticon/')
            api
                .get('/panopticon/')
                .then(response => {
                globalState.homepageState = response.data;
                globalState.isInitialized = true;
            })
                .catch(error => {
                if (error.response?.status === 401) {
                    router.push('/login/');
                }
            });
            // console.log('initialization done. data:', resp.data)
        }
        else {
            console.log('we are initialized!');
        }
        return {
            isInitialized,
        };
    },
});
