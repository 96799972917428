import { defineComponent, toRef } from 'vue';
import { globalState } from '@/globalStore';
import Searchbar from '@/components/ui/Searchbar.vue';
import LogListItem from '@/components/logs/LogListItem.vue';
import { api } from '@/composition/api';
export default defineComponent({
    components: {
        LogListItem,
        Searchbar,
    },
    setup() {
        console.log('dashboard loaded');
        const searchInitialized = toRef(globalState.search, 'initialized');
        if (!searchInitialized.value) {
            console.log('search not initialized');
            api
                .get('/panopticon/search-data/')
                .then(response => {
                globalState.search.data = response.data;
                globalState.search.initialized = true;
            })
                .catch(error => {
                console.warn('Search init error! ', error);
            });
            // console.log('initialization done. data:', resp.data)
        }
        else {
            console.log('search is initialized');
        }
        const feedbackCount = toRef(globalState.homepageState, 'feedbackCount');
        const invitesCount = toRef(globalState.homepageState, 'invitesCount');
        const subscriptions = toRef(globalState.homepageState, 'subscriptions');
        const activeSessions = toRef(globalState.homepageState, 'activeSessions');
        const users = toRef(globalState.homepageState, 'users');
        const artists = toRef(globalState.homepageState, 'artists');
        const logs = toRef(globalState.homepageState, 'logs');
        return {
            feedbackCount,
            invitesCount,
            subscriptions,
            activeSessions,
            users,
            artists,
            logs,
        };
    },
});
