import { defineComponent, toRef } from 'vue';
import SidebarNavItem from './SidebarNavItem.vue';
import { globalState } from '@/globalStore';
export default defineComponent({
    components: {
        SidebarNavItem,
    },
    setup() {
        const navItems = toRef(globalState, 'navigationItems');
        return {
            SidebarNavItem,
            navItems,
        };
    },
});
