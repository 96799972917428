import { defineComponent } from 'vue';
import { globalState } from '../globalStore';
import { api } from '@/composition/api';
export default defineComponent({
    created() {
        globalState.isAuthenticated = false;
        api
            .post('/session-auth/logout/')
            .then(function () {
            globalState.isAuthenticated = false;
            console.log('logged out!');
            // Force refresh the page
            window.location.href = '/';
        })
            .catch(function () {
            console.warn('Logout failed.');
        });
    },
});
