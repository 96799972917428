import { defineComponent, computed } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const joined = computed(() => dayjs(props.item.meta.created).format('MMM D, YYYY h:mm A'));
        const lastSeen = computed(() => {
            if (props.item.meta.lastSeen) {
                return dayjs(props.item.meta.lastSeen).format('MMM D, YYYY h:mm A');
            }
            else {
                return dayjs(props.item.meta.created).format('MMM D, YYYY h:mm A');
            }
        });
        const email = () => {
            if (process.env.NODE_ENV === 'development') {
                return `anonymized-${props.item.id}@example.com`;
            }
            else {
                return props.item.email;
            }
        };
        return {
            name: props.item.artistName,
            photoUrl: props.item.photoUrl,
            email: email(),
            eventCount: props.item.meta.eventCount,
            joined,
            lastSeen,
        };
    },
});
