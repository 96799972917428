import { defineComponent, toRef, watch } from 'vue';
import SidebarContent from './SidebarContent.vue';
import { globalState } from '@/globalStore';
export default defineComponent({
    components: {
        SidebarContent,
    },
    setup() {
        console.log('Sidebar loaded');
        const sidebarOpen = toRef(globalState, 'sidebarOpen');
        const searchFlyoutOpen = toRef(globalState, 'searchFlyoutOpen');
        watch(sidebarOpen, sidebarOpen => {
            if (sidebarOpen) {
                searchFlyoutOpen.value = false;
                console.log('Closing search flyout');
            }
        });
        return {
            sidebarOpen,
        };
    },
});
