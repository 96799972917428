const basicLogFn = function (message, obj) {
    if (obj) {
        console.log(message, obj);
    }
    else {
        console.log(message);
    }
};
export class BetterLogger {
    constructor(options) {
        this.name = '';
        this.level = options.level;
        if (options.packageName) {
            this.name = options.packageName;
        }
        if (options.logFn) {
            this.logFn = options.logFn;
        }
        else {
            this.logFn = basicLogFn;
        }
    }
    formatMsg(msg) {
        if (this.name.length > 0) {
            return `[${this.name}] ${msg}`;
        }
        else {
            return msg;
        }
    }
    getCallerName() {
        let error = {};
        try {
            throw new Error('');
        }
        catch (e) {
            error = e;
        }
        if (error.stack === undefined) {
            return '';
        }
        else {
            try {
                const stack = error.stack.split('\n')[2];
                return stack.split('@')[0] + '()';
            }
            catch (e) {
                return '';
            }
        }
    }
    log(message, obj = undefined) {
        this.logFn(this.formatMsg(message), obj);
    }
    info(message, obj = undefined) {
        console.info(this.formatMsg(message), obj);
    }
    warn(message, obj = undefined) {
        console.warn(this.formatMsg(message), obj);
    }
    error(message, obj = undefined) {
        console.error(this.formatMsg(message), obj);
    }
    success(message, obj = undefined) {
        this.log('✅ ' + message, obj);
    }
    run(message = '', obj = undefined) {
        if (message.length > 0) {
            this.log('🚀 ' + message, obj);
        }
        else {
            this.log('🚀 ' + this.getCallerName(), obj);
        }
    }
}
// const loggingOptions = {
//   level: 'DEBUG',
//   packageName: 'DataTable',
// }
// export const debug = new DebugLogger(loggingOptions)
