import { defineComponent, ref, toRef, computed } from 'vue';
import { globalState } from '@/globalStore';
import MiniSearch from 'minisearch';
export default defineComponent({
    async setup() {
        const profileOpen = ref(false);
        const searchFlyoutOpen = toRef(globalState, 'searchFlyoutOpen');
        const sidebarOpen = toRef(globalState, 'sidebarOpen');
        console.time('MiniSearch init');
        const searchObj = new MiniSearch({
            fields: ['userName', 'eventName', 'artistName', 'email', 'extraSearchField'],
            storeFields: [
                'userName',
                'eventName',
                'artistName',
                'email',
                'extraSearchField',
                'model',
                'photoUrl',
                'meta',
            ],
            searchOptions: {
                boost: { userName: 4, artistName: 4, eventName: 2 },
                prefix: true,
                fuzzy: 0.3,
            },
        });
        searchObj.addAll(globalState.search.data);
        console.timeEnd('MiniSearch init');
        const searchQuery = ref('');
        const searchResults = computed(() => searchObj.search(searchQuery.value));
        const userResults = computed(() => searchObj.search(searchQuery.value, {
            filter: result => result.model === 'user',
        }));
        const artistResults = computed(() => searchObj.search(searchQuery.value, {
            filter: result => result.model === 'artist',
        }));
        const closeFlyout = function (force = false) {
            if (force) {
                searchQuery.value = '';
                // eslint-disable-next-line
                document.getElementById('search_field')?.blur();
            }
            if (searchQuery.value === '') {
                searchFlyoutOpen.value = false;
            }
        };
        return {
            profileOpen,
            searchFlyoutOpen,
            sidebarOpen,
            searchQuery,
            searchResults,
            userResults,
            artistResults,
            closeFlyout,
        };
    },
});
