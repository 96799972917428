import { defineComponent, inject, watchEffect } from 'vue';
import TdItem from './TdItem.vue';
import { debug } from './utils/dev';
export default defineComponent({
    components: {
        TdItem,
    },
    setup(_, { slots }) {
        let dataKeys = [];
        let keysShifted = false;
        const state = inject('state');
        function extractDataKeys() {
            if (!keysShifted && state.data.current && state.data.current.value.length > 0) {
                debug.run('extractDataKeys');
                dataKeys = Object.keys(state.data.current.value[0]);
                dataKeys.reverse();
                state.data.current.value.unshift({});
                keysShifted = true;
            }
        }
        if (!slots.default) {
            watchEffect(() => {
                extractDataKeys();
            });
        }
        return {
            data: state.data.current,
            breakpoint: state.currentBreakpoint,
            dataKeys,
        };
    },
});
